import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <Fragment>
            <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 51.575523401904306,197.56223183565632 103.15104680380861,195.12446367131264 147,194 C 190.8489531961914,192.87553632868736 226.97133618666987,193.06437715040576 262,218 C 297.0286638133301,242.93562284959424 330.96360844951204,292.61802772706426 376,271 C 421.03639155048796,249.38197227293574 477.17423001528186,156.46351194133717 521,125 C 564.8257699847181,93.53648805866281 596.3394714893601,123.52792450758696 633,169 C 669.6605285106399,214.47207549241304 711.4678840272773,275.424790028315 768,264 C 824.5321159727227,252.57520997168504 895.7889924015309,168.77291537915318 938,148 C 980.2110075984691,127.22708462084684 993.3761463665999,169.48354845507234 1027,177 C 1060.6238536334001,184.51645154492766 1114.7064221320693,157.29289080055744 1168,167 C 1221.2935778679307,176.70710919944256 1273.798165105123,223.34488834269789 1319,235 C 1364.201834894877,246.65511165730211 1402.1009174474384,223.32755582865104 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#9072d1ff" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
            <div className="footer bg-info py-4 d-flex flex-lg-column">
                <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">

                    {/* Company note */}
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-white font-weight-bold mr-2">2025 ©</span>
                        <Link to="/" target="_blank" className="text-white text-hover">Halo Technology Lab Ltd</Link>
                    </div>

                    {/* Footer links */}
                    <div className="nav nav-dark">
                        <ul className="list-group list-group-flush mx-10">
                            <Link to="/about" target="_blank" className="nav-link font-size-lg text-white pl-0 pr-5 list-group-item bg-transparent footer-link">About</Link>
                        </ul>
                        <ul className="list-group list-group-flush mx-10">
                            <Link to="/services" target="_blank" className="nav-link font-size-lg text-white pl-0 pr-5 list-group-item bg-transparent footer-link">Services</Link>
                            <Link to="/portfolio" target="_blank" className="nav-link font-size-lg text-white pl-0 pr-5 list-group-item bg-transparent footer-link">Portfolio</Link>
                            <Link to="/#testimonials" target="_blank" className="nav-link font-size-lg text-white pl-0 pr-5 list-group-item bg-transparent footer-link">Testimonials</Link>
                        </ul>
                        <ul className="list-group list-group-flush mx-10">
                            {/* <Link to="/#contact" target="_blank" className="nav-link font-size-lg text-white pl-0 pr-5 list-group-item bg-transparent footer-link">Get in touch</Link> */}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Footer
